<template>
    <div>
        <v-dialog
            v-model="$store.state.dialog"
            width="80%"
            :fullscreen="isfullscreen"
            persistent
            transition="dialog-bottom-transition"
        >
            <v-card flat>
                <v-toolbar
                    tile
                    :color="isfullscreen ? 'primary' : 'primary'"
                    flat
                >
                    <v-toolbar-title class="white--text">
                        {{
                            $store.state.bodyDetails.dialogType == "add"
                                ? "اضافة"
                                : "تعديل"
                        }}
                        حجز جديد
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn
                        small
                        text
                        class="ml-2"
                        color="white"
                        @click="isfullscreen = !isfullscreen"
                    >
                        <v-icon>
                            {{
                                isfullscreen
                                    ? "mdi-arrow-collapse"
                                    : "mdi-arrow-expand"
                            }}
                        </v-icon>
                    </v-btn>
                    <v-btn @click="close()" depressed small color="error">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-col>
                    <v-form
                        @submit.prevent="submit()"
                        ref="form"
                        class="pa-2"
                        v-model="valid"
                        lazy-validation
                    >
                        <v-row no-gutters>
                            <!-- checkin Date -->
                            <v-col cols="12" md="2" class="pl-2">
                                <small class="font-weight-bold">
                                    <span class="red--text font-weight-bold"
                                        >*</span
                                    >
                                    تاريخ الحجز
                                </small>
                                <v-menu
                                    ref="menuCheckinDate"
                                    v-model="menuCheckinDate"
                                    :close-on-content-click="false"
                                    :return-value.sync="checkinDate"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="checkinDate"
                                            append-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            filled
                                            hide-details="auto"
                                            color="secondary"
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker
                                        v-model="checkinDate"
                                        no-title
                                        color="secondary"
                                        scrollable
                                    >
                                        <v-btn
                                            text
                                            color="success"
                                            @click="
                                                $refs.menuCheckinDate.save(
                                                    checkinDate
                                                )
                                            "
                                        >
                                            موافق
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="error"
                                            @click="menuCheckinDate = false"
                                        >
                                            الغاء
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- checkinTime -->
                            <v-col cols="12" md="2" class="pl-2">
                                <small class="font-weight-bold">
                                    <span class="red--text font-weight-bold"
                                        >*</span
                                    >
                                    وقت الحجز
                                </small>
                                <v-dialog
                                    ref="dialog"
                                    v-model="checkinTimeModal"
                                    :return-value.sync="checkinTime"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="checkinTime"
                                            append-icon="mdi-clock-time-four-outline"
                                            readonly
                                            outlined
                                            filled
                                            hide-details="auto"
                                            placeholder="08:00 AM"
                                            color="primary"
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="checkinTimeModal"
                                        v-model="checkinTime"
                                        format="ampm"
                                        full-width
                                    >
                                        <v-btn
                                            text
                                            color="error"
                                            @click="checkinTimeModal = false"
                                        >
                                            الغاء
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="
                                                $refs.dialog.save(checkinTime)
                                            "
                                        >
                                            موافق
                                        </v-btn>

                                        <v-spacer></v-spacer>
                                    </v-time-picker>
                                </v-dialog>
                            </v-col>

                            <!-- checkout Date -->
                            <v-col cols="12" md="2" class="pl-2">
                                <small class="font-weight-bold">
                                    تاريخ المغادرة
                                </small>
                                <v-menu
                                    ref="menuCheckoutDate"
                                    v-model="menuCheckoutDate"
                                    :close-on-content-click="false"
                                    :return-value.sync="checkoutDate"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="checkoutDate"
                                            append-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            filled
                                            hide-details="auto"
                                            color="primary"
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker
                                        v-model="checkoutDate"
                                        no-title
                                        color="primary"
                                        scrollable
                                    >
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="
                                                $refs.menuCheckoutDate.save(
                                                    checkoutDate
                                                )
                                            "
                                        >
                                            موافق
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="error"
                                            @click="menuCheckoutDate = false"
                                        >
                                            الغاء
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- checkoutTime -->
                            <v-col cols="12" md="2" class="pl-2">
                                <small class="font-weight-bold">
                                    وقت المغادرة
                                </small>
                                <v-dialog
                                    ref="dialogOut"
                                    v-model="checkoutTimeModel"
                                    :return-value.sync="checkoutTime"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="checkoutTime"
                                            append-icon="mdi-clock-time-four-outline"
                                            readonly
                                            outlined
                                            filled
                                            format="ampm"
                                            hide-details="auto"
                                            placeholder="12:00 PM"
                                            color="secondary"
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="checkoutTimeModel"
                                        v-model="checkoutTime"
                                        format="ampm"
                                        full-width
                                    >
                                        <v-btn
                                            text
                                            color="error"
                                            @click="checkoutTimeModel = false"
                                        >
                                            الغاء
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="
                                                $refs.dialogOut.save(
                                                    checkoutTime
                                                )
                                            "
                                        >
                                            موافق
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-time-picker>
                                </v-dialog>
                            </v-col>

                            <!-- reservationType -->
                            <v-col cols="12" md="2" class="pl-2">
                                <small class="font-weight-bold">
                                    <span class="red--text">*</span>
                                    نوع الحجز
                                </small>
                                <v-select
                                    :items="reservationTypeItem"
                                    v-model="reservationType"
                                    item-text="text"
                                    item-value="value"
                                    dense
                                    filled
                                    placeholder="حدد  نوع الحجز"
                                    hide-details="auto"
                                    required
                                    :rules="[required()]"
                                    outlined
                                    persistent-hint
                                    small-chips
                                >
                                </v-select>
                            </v-col>

                            <!-- delegation Count -->
                            <v-col
                                cols="12"
                                md="2"
                                v-if="reservationType == '1'">
                                <small class="font-weight-bold">
                                    <span class="red--text">*</span>
                                    عدد الاعضاء
                                    <span class="grey--text">
                                        مطلوب اذا كان نوع الحجز وفد
                                    </span>
                                </small>
                                <vue-numeric-input
                                    class="numeric-input"
                                    v-model="body.delegationCount"
                                    :min="0"
                                    placeholder="حدد  عدد الاعضاء"
                                    align="center"
                                >
                                </vue-numeric-input>
                            </v-col>
                        </v-row>

                        <v-row no-gutters class="mt-4">
                            <!-- delegationName -->
                            <v-col
                                v-if="reservationType == '1'"
                                cols="12"
                                md="3"
                                class="pl-2"
                            >
                                <small class="font-weight-bold">
                                    <span class="red--text">*</span>
                                    اسم الوفد
                                    <span class="grey--text"
                                        >مطلوب اذا كان نوع الحجز وفد</span
                                    >
                                </small>
                                <v-text-field
                                    v-model="body.delegationName"
                                    required
                                    outlined
                                    dense
                                    hide-details="auto"
                                    color="secondary"
                                    filled
                                    placeholder="اسم الوفد"
                                >
                                </v-text-field>
                            </v-col>

                            <!-- delegationID -->
                            <v-col
                                v-if="reservationType == '1'"
                                cols="12"
                                md="3"
                                class="pl-2"
                            >
                                <small class="font-weight-bold">
                                    <span class="red--text">*</span>
                                    معرف الوفد
                                    <span class="grey--text">
                                        مطلوب اذا كان نوع الحجز وفد
                                    </span>
                                </small>
                                <v-text-field
                                    v-model="body.delegationID"
                                    required
                                    outlined
                                    dense
                                    hide-details="auto"
                                    color="secondary"
                                    filled
                                    placeholder="معرف الوفد"
                                >
                                </v-text-field>
                            </v-col>

                            <!-- identifierGuid -->
                            <v-col cols="12" md="2" class="pl-2" v-if="reservationType == '1'">
                                <small class="font-weight-bold">
                                    <span class="red--text font-weight-bold">*</span>
                                    اختار الشخص المعرف
                                    <span class="grey--text">
                                        مطلوب اذا كان نوع الحجز وفد
                                    </span>
                                </small>
                                <identifiers-list label="اختار المعرف" />
                            </v-col>

                            <!-- rooms -->
                            <v-col cols="12" md="4">
                                <small class="font-weight-bold">
                                    <span class="red--text font-weight-bold"
                                        >*</span
                                    >
                                    حدد الغرف المراد حجزها
                                    <small class="primary--text px-2">
                                        غرفة او أكثر
                                    </small>
                                </small>
                                <!-- <v-autocomplete
                                    v-model="reservedRooms"
                                    v-debounce:500ms="getRooms"
                                    :items="listRooms"
                                    item-text="roomNumber"
                                    item-value="roomId"
                                    :loading="loadingRooms"
                                    label="اختار الغرف "
                                    multiple
                                    dense
                                    small-chips
                                    no-data-text="لا توجد بيانات"
                                    filled
                                    clearable
                                    return-object
                                    deletable-chips
                                    :search-input.sync="searchinput"
                                    outlined
                                    hide-details="auto"
                                /> -->

                                <v-combobox
                                    v-model="reservedRooms"
                                    :items="listRooms"
                                    item-text="roomNumber"
                                    item-value="roomId"
                                    return-object
                                    deletable-chips
                                    label="اختار الغرف"
                                    no-data-text="لا توجد بيانات"
                                    clearable
                                    filled
                                    multiple
                                    dense
                                    outlined
                                    small-chips
                                ></v-combobox>
                            </v-col>

                            <!-- note -->
                            <v-col cols="12" md="12" class="mt-3">
                                <small>
                                    اكتب اي ملاحضات في خصوص الحجز
                                </small>
                                <v-textarea
                                    class="mt-1"
                                    v-model="body.notes"
                                    required
                                    filled
                                    outlined
                                    label="لا توجد ملاحضات"
                                    value="لا توجد ملاحضات"
                                    color="secondary">
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <div>
                            <v-card outlined flat>
                                <div class="d-flex justify-space-between">
                                    <v-btn
                                        @click="dialogGuests = true"
                                        class="mb-2"
                                        elevation="2"
                                        plain
                                        raised
                                        color="primary"
                                    >
                                        اضافة نزيل
                                    </v-btn>
                                    <p
                                        class="mx-auto font-weight-bold primary--text"
                                    >
                                        النزلاء
                                    </p>
                                </div>

                                <v-data-table
                                    :headers="headersGuests"
                                    :items="itemGuests"
                                    loader-height="10"
                                    :loading="loading"
                                    no-data-text="لا توجد بيانات"
                                    loading-text="جاري تحميل البيانات"
                                    hide-default-footer
                                >
                                    <template
                                        v-slot:[`item.documentTypeId`]="{
                                            item
                                        }"
                                    >
                                        <span class="primary--text">
                                            {{
                                                items.find(
                                                    ({ id }) =>
                                                        id ==
                                                        item.documentTypeId
                                                ).documentName
                                            }}
                                        </span>
                                    </template>

                                    <template
                                        v-slot:[`item.isChiled`]="{ item }"
                                    >
                                        <span class="primary--text">
                                            {{
                                                item.isChiled == "true"
                                                    ? "طــفل"
                                                    : "بــالغ"
                                            }}
                                        </span>
                                    </template>

                                    <template
                                        v-slot:[`item.documentPath`]="{ item }"
                                    >
                                        <v-avatar class="ma-3" tile size="40">
                                            <img
                                                alt="Hotel System"
                                                :src="url + item.documentPath"
                                            />
                                        </v-avatar>
                                    </template>

                                    <template
                                        v-slot:[`item.actions`]="{ item }"
                                    >
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    @click="
                                                        editDialogGuests(item)
                                                    "
                                                    v-on="on"
                                                    fab
                                                    x-small
                                                    depressed
                                                >
                                                    <v-icon color="secondary">
                                                        mdi-pencil
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>تعديل</span>
                                        </v-tooltip>

                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    @click="removeGuests(item)"
                                                    v-on="on"
                                                    fab
                                                    x-small
                                                    depressed
                                                    class="mr-2"
                                                >
                                                    <v-icon color="error">
                                                        mdi-delete
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>حذف</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>

                                <v-dialog
                                    persistent
                                    v-model="dialogGuests"
                                    width="50%"
                                >
                                    <v-card flat>
                                        <v-card-title class="grey lighten-2">
                                            اضافة نزيل جديد
                                            <v-spacer></v-spacer>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row no-gutters class="mt-4">
                                                <!-- fullName -->
                                                <v-col
                                                    cols="12"
                                                    md="5"
                                                    class="pl-2">
                                                    <small
                                                        class="font-weight-bold"
                                                    >
                                                        الاسم الكامل
                                                        <span
                                                            class="red--text font-weight-bold"
                                                            >*</span
                                                        >
                                                    </small>
                                                    <v-text-field
                                                        v-model="
                                                            guests.fullName
                                                        "
                                                        outlined
                                                        filled
                                                        dense
                                                        :rules="[required()]"
                                                        hide-details="auto"
                                                        color="secondary"
                                                        placeholder=" اكتب هنا الاسم الكامل"
                                                    >
                                                    </v-text-field>
                                                </v-col>

                                                <!-- isChiled  -->
                                                <v-col
                                                    cols="12"
                                                    md="5"
                                                    class="pl-2 pb-3"
                                                >
                                                    <small
                                                        class="font-weight-bold mr-5"
                                                    >
                                                        طــفل / بـــالغ
                                                        <span
                                                            class="red--text font-weight-bold"
                                                            >*</span
                                                        >
                                                    </small>
                                                    <v-radio-group
                                                        class="mt-n1 pt-3"
                                                        :rules="[required()]"
                                                        v-model="
                                                            guests.isChiled
                                                        "
                                                        hide-details="auto"
                                                        mandatory
                                                        row
                                                    >
                                                        <v-radio
                                                            label="بالغ"
                                                            value="false"
                                                        ></v-radio>
                                                        <v-radio
                                                            label="طفل"
                                                            value="true"
                                                        ></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mt-4">
                                                <!-- document Type  -->
                                                <v-col
                                                    cols="12"
                                                    md="3"
                                                    class="pl-2"
                                                >
                                                    <small
                                                        class="font-weight-bold"
                                                    >
                                                        اختار نوع الوثيقة
                                                        <span
                                                            class="red--text font-weight-bold"
                                                            >*</span
                                                        >
                                                    </small>
                                                    <v-autocomplete
                                                        v-model="
                                                            guests.documentTypeId
                                                        "
                                                        v-debounce:500ms="
                                                            getDocumentTypes
                                                        "
                                                        :items="items"
                                                        item-text="documentName"
                                                        item-value="id"
                                                        :loading="
                                                            loadingautocomplete
                                                        "
                                                        label="نوع الوثيقة"
                                                        dense
                                                        :rules="[required()]"
                                                        no-data-text="لا توجد بيانات"
                                                        filled
                                                        outlined
                                                        hide-details="auto"
                                                    />
                                                </v-col>

                                                <!-- documentNo -->
                                                <v-col
                                                    cols="12"
                                                    md="3"
                                                    class="pl-2"
                                                >
                                                    <small
                                                        class="font-weight-bold"
                                                    >
                                                        رقم الوثيقة
                                                        <span
                                                            class="red--text font-weight-bold"
                                                            >*</span
                                                        >
                                                    </small>
                                                    <v-text-field
                                                        v-model="
                                                            guests.documentNo
                                                        "
                                                        outlined
                                                        :rules="[required()]"
                                                        filled
                                                        dense
                                                        hide-details="auto"
                                                        color="secondary"
                                                        placeholder=" رقم الوثيقة"
                                                    >
                                                    </v-text-field>
                                                </v-col>

                                                <!-- issuer -->
                                                <v-col
                                                    cols="12"
                                                    md="3"
                                                    class="pl-2"
                                                >
                                                    <small
                                                        class="font-weight-bold"
                                                    >
                                                        جهة الاصدار
                                                    </small>
                                                    <v-text-field
                                                        v-model="guests.issuer"
                                                        outlined
                                                        filled
                                                        dense
                                                        hide-details="auto"
                                                        color="secondary"
                                                        placeholder="جهة اصدار الوثيقة"
                                                    >
                                                    </v-text-field>
                                                </v-col>

                                                <!-- documentPath -->
                                                <v-col
                                                    cols="12"
                                                    md="4"
                                                    class="mt-3"
                                                >
                                                    <small>
                                                        هوبة أحوال, بطاقة وطنية
                                                        موحدة, بطاقة سكن , جواز
                                                        سفر,
                                                    </small>
                                                    <v-file-input
                                                        @change="
                                                            handleDocumentPathUpload()
                                                        "
                                                        v-model="documentPath"
                                                        counter
                                                        chips
                                                        dense
                                                        show-size
                                                        accept="image/png, image/jpeg, image/bmp, image/jpg, .pdf"
                                                        color="error"
                                                        :loading="
                                                            documentPathloading
                                                        "
                                                        prepend-icon
                                                        append-icon="mdi-camera"
                                                        label="حدد المستمسكات المراد رفعها"
                                                        outlined
                                                        filled
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-card-text>

                                        <v-divider></v-divider>

                                        <v-card-actions>
                                            <v-btn
                                                v-if="editGuest == false"
                                                color="primary"
                                                @click="addGuests()"
                                                :disabled="
                                                    !guests.fullName ||
                                                        !guests.documentNo ||
                                                        !guests.isChiled ||
                                                        !guests.documentTypeId
                                                "
                                            >
                                                حفظ
                                            </v-btn>

                                            <v-btn
                                                v-if="editGuest == true"
                                                color="primary"
                                                @click="editGuests()"
                                                :disabled="
                                                    !guests.fullName ||
                                                        !guests.documentNo ||
                                                        !guests.isChiled ||
                                                        !guests.documentTypeId
                                                "
                                            >
                                                تعديل
                                            </v-btn>

                                            <v-btn
                                                color="error"
                                                @click="CloseDialogGuests()"
                                            >
                                                الغاء
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-card>
                        </div>

                        <v-row class="mt-n2">
                            <v-col cols="12" md="12" class="mt-2">
                                <v-btn
                                    type="submit"
                                    :disabled="
                                        !reservationType ||
                                            !checkinDate ||
                                            !reservedRooms[0]"
                                    :loading="loading"
                                    x-large
                                    color="primary">
                                    حفظ
                                </v-btn>
                                <v-btn
                                    @click="close()"
                                    x-large
                                    color="error"
                                    class="mr-2"
                                    outlined>
                                    إلغاء
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from "@/axios/http-common";
import validations from "@/utils/validations";
import { mapState } from "vuex";
import VueNumericInput from "vue-numeric-input";
import IdentifiersList from "../../components/lists/IdentifiersList.vue";

export default {
    components: {
        VueNumericInput,
        IdentifiersList
    },

    data: () => ({
        isfullscreen: true,
        valid: true,
        body: {},
        loading: false,
        ...validations,
        menuCheckinDate: false,
        checkinDate: new Date().toISOString().substr(0, 10),
        checkinTime: "08:00",
        checkinTimeModal: false,
        menuCheckoutDate: false,
        checkoutDate: null,
        checkoutTime: null,
        checkoutTimeModel: false,
        reservationType: "0",
        reservationTypeItem: [
            {
                text: "عادي",
                value: "0"
            },
            {
                text: "وفد",
                value: "1"
            }
        ],
        loadingautocomplete: false,
        items: [],
        // getRooms list
        loadingRooms: false,
        reservedRooms: [],
        listRooms: [],

        guests: {},
        dialogGuests: false,
        headersGuests: [
            {
                text: "الاسم الكامل",
                value: "fullName"
            },
            {
                text: "طــفل / بـــالغ",
                value: "isChiled"
            },
            {
                text: "نوع الوقيقة",
                value: "documentTypeId"
            },
            {
                text: "رقم الوقيقة",
                value: "documentNo"
            },
            {
                text: "جهة الاصدار ",
                value: "issuer"
            },
            {
                text: "المستمسكات",
                value: "documentPath",
                sortable: false
            },
            {
                text: "الاجراءات",
                value: "actions",
                align: "left"
            }
        ],
        itemGuests: [],
        documentPath: null,
        documentPathToDb: "",
        documentPathloading: false,
        detail: {},
        editGuest: false,
        itemGuest: {}
    }),

    props: ["id"],

    computed: {
        ...mapState([
            "bodyDetails",
            "url",
            "reservedRoomFromHome"
        ]),
        currentUser() {
            return this.$store.state.auth.user;
        }
    },

    created() {
        this.body = {};
        this.getDocumentTypes();
        this.getRooms();
        if (this.reservedRoomFromHome.roomId != null)
            this.reservedRooms.push(this.reservedRoomFromHome);
        else this.reservedRooms = [];
    },

    mounted() {
        this.$eventBus.$on("refresh-rooms", () => {
            this.getRooms();
        });
    },

    methods: {
        submit() {
            if (this.bodyDetails.dialogType == "add") {
                this.addItem();
            }
        },

        addItem() {
            this.loading = true;
            this.body.identifierId = this.$store.state.identifierId;
            this.body.checkinDate = this.checkinDate;
            this.body.checkinTime = this.checkinTime;
            this.body.checkoutTime = this.checkoutTime;
            this.body.checkoutDate = this.checkoutDate;
            this.body.reservationType = this.reservationType;
            this.body.guests = this.itemGuests;
            this.body.reservedRooms = this.reservedRooms;
            this.$GenericService
                .create("reservation", this.body)
                .then(res => {
                    this.close();
                    this.$store.dispatch("setSnackbar", {
                        text: `تم اضافة الحجز بنجاح`
                    });
                    this.$eventBus.$emit(this.$route.path);
                })
                .catch(err => {
                    this.$store.dispatch("setSnackbar", {
                        text: ` ${err.response.data.message}`,
                        color: "error"
                    });
                })
                .finally(() => (this.loading = false));
        },

        getDocumentTypes() {
            this.loading = true;
            this.$GenericService
                .getAll("documentType/getDocumentTypes")
                .then(res => {
                    this.items = res.data.data;
                })
                .catch(err => {})
                .finally(() => (this.loading = false));
        },

        getRooms() {
            this.loadingRooms = true;
            this.$GenericService
                .getAll("room/listRooms")
                .then(res => {
                    this.listRooms = res.data.data;
                })
                .catch(err => {})
                .finally(() => (this.loadingRooms = false));
        },

        handleDocumentPathUpload() {
            this.documentPathloading = true;
            let formData = new FormData();
            formData.append("files", this.documentPath);
            http.post("uploadFile/postUploadFiles", formData)
                .then(res => {
                    let filePaths = res.data.fileList;
                    filePaths.forEach((element, i) => {
                        console.log("path => " + element.path);
                        this.documentPathToDb = element.path;
                    });
                })
                .catch(err => {})
                .finally(() => (this.documentPathloading = false));
        },

        addGuests() {
            this.itemGuests.push({
                fullName: this.guests.fullName,
                documentTypeId: this.guests.documentTypeId,
                documentNo: this.guests.documentNo,
                issuer: this.guests.issuer,
                isChiled: this.guests.isChiled,
                documentPath: this.documentPathToDb
            });
            this.CloseDialogGuests();
        },

        editDialogGuests(item) {
            this.editGuest = true;
            this.itemGuest = item;
            this.dialogGuests = true;

            this.guests.fullName = item.fullName;
            this.guests.documentTypeId = item.documentTypeId;
            this.guests.documentNo = item.documentNo;
            this.guests.issuer = item.issuer;
            this.guests.isChiled = item.isChiled;
            this.guests.documentPath = item.documentPath;
            this.documentPathToDb = item.documentPath;
        },

        editGuests() {
            var objIndex = this.itemGuests.indexOf(this.itemGuest);
            this.itemGuests[objIndex].fullName = this.guests.fullName;
            this.itemGuests[
                objIndex
            ].documentTypeId = this.guests.documentTypeId;
            this.itemGuests[objIndex].documentNo = this.guests.documentNo;
            this.itemGuests[objIndex].issuer = this.guests.issuer;
            this.itemGuests[objIndex].isChiled = this.guests.isChiled;
            this.itemGuests[objIndex].documentPath = this.documentPathToDb;
            this.CloseDialogGuests();
        },

        removeGuests(i) {
            const index = this.itemGuests.indexOf(i);
            if (index > -1) {
                this.itemGuests.splice(index, 1);
            }
        },

        close() {
            this.$store.dispatch("setDialog");
            this.body = {};
            this.checkinDate = new Date().toISOString().substr(0, 10);
            this.checkinTime = "08:00";
            this.checkoutDate = null;
            this.checkoutTime = null;
            this.resetValidation();
            this.$store.dispatch("setBodyDetails", {});
            this.guests = {};
            this.itemGuests = [];
            this.reservedRooms = [];
            this.$store.state.identifierId = null;
            this.$eventBus.$emit("RefreshHomePage");

        },

        CloseDialogGuests() {
            this.documentPathToDb = "";
            this.documentPath = null;
            this.dialogGuests = false;
            this.editGuest = false;
            this.guests = {};
            this.resetValidation();
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        }
    }
};
</script>

<style lang="scss">
.btn-add-guests {
    position: absolute;
    right: 10px;
    top: -15px;
}
</style>
