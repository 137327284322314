<template>
    <div>
        <v-autocomplete
            v-model="$store.state.staffId"
            v-debounce:500ms="getData"
            :items="items"
            item-text="fullName"
            item-value="id"
            :loading="loading"
            :label="label"
            dense
            no-data-text="لا توجد بيانات"
            filled
            outlined
            hide-details="auto"
        />
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            items: [],
            search: ""
        };
    },

    props: ["label", "serviceType"],

    created() {
        this.getData();
    },

    methods: {
        getData() {
            this.loading = true;
            this.$GenericService
                .getAll("staff/getStaffs/?serviceType=" + this.serviceType)
                .then(res => {
                    this.items = res.data.data;
                })
                .catch(err => {
                    // console.log(err);
                })
                .finally(() => (this.loading = false));
        }
    }
};
</script>
