<template>
    <div>
        <v-dialog
            v-model="$store.state.dialogOpertionRoom"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '50%'"
            persistent=""
        >
            <v-card flat>
                <v-toolbar color="primary" dark>
                    <v-toolbar-title>
                        {{
                            $store.state.bodyDetailsOpertionRoom.dialogType == "add"
                                ? "إضافة"
                                : "تعديل"
                        }}
                        عملية
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn @click="close()" color="error">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-form
                    @submit.prevent="submit()"
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                    <v-row no-gutters class="mt-6 pa-3">
                        <v-col cols="12" md="4" class="pl-2">
                            الحجز
                            <small class="green--text"> (اختاري) </small>
                            <reservations-list label="اختار الحجز" />
                        </v-col>

                        <v-col cols="12" md="4" class="pl-2">
                            اختار الموظف
                            <span class="red--text font-weight-bold">*</span>
                            <staffs-list serviceType="" label="اختار الموظف " />
                        </v-col>

                        <!-- operationType -->
                        <v-col cols="12" md="4" class="pl-2">
                            <small class="font-weight-bold">
                                نوع العملية
                                <span class="red--text font-weight-bold"
                                    >*</span
                                >
                            </small>
                            <v-select
                                :items="operationTypeItem"
                                v-model="body.operationType"
                                item-text="text"
                                item-value="value"
                                dense
                                filled
                                placeholder="حدد نوع العملية"
                                hide-details="auto"
                                required
                                :rules="[required()]"
                                outlined
                                persistent-hint
                                small-chips
                            >
                            </v-select>
                        </v-col>

                        <!-- room -->
                        <v-col cols="12" md="5" class="pl-2 mt-4">
                            <span class="font-weight-bold">
                                حدد الغرفة المراد عمل لةا
                                <small class="green--text"> تظيف , صيانة</small>
                                <span class="red--text font-weight-bold"
                                    >*</span
                                >
                            </span>
                            <v-autocomplete
                                v-model="body.roomId"
                                v-debounce:500ms="getRooms"
                                :items="listRooms"
                                item-text="roomNumber"
                                item-value="roomId"
                                :loading="loadingRooms"
                                label="اختار الغرف "
                                dense
                                no-data-text="لا توجد بيانات"
                                filled
                                outlined
                                hide-details="auto"
                            />
                        </v-col>

                        <!-- notes -->
                        <v-col cols="12" md="12" class="mt-4">
                            <small class="font-weight-bold mb-4">
                                اكتب الملاحظات ان وجدت
                            </small>
                            <small class="green--text"> (اختاري) </small>
                            <v-textarea
                                v-model="body.notes"
                                required
                                outlined
                                label="لا توجد ملاحضات"
                                color="secondary"
                            >
                            </v-textarea>
                        </v-col>

                        <v-col cols="12" md="12" class="mt-2 ">
                            <v-btn
                                type="submit"
                                :disabled="
                                    !body.roomId ||
                                        !$store.state.staffId ||
                                        !body.operationType
                                "
                                :loading="loading"
                                x-large
                                color="primary"
                            >
                                حفظ
                            </v-btn>
                            <v-btn
                                @click="close()"
                                x-large
                                color="error"
                                class="mr-2"
                                outlined
                            >
                                إلغاء
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import validations from "@/utils/validations";
import { mapState } from "vuex";
import ReservationsList from "../../components/lists/ReservationsList.vue";
import StaffsList from "../../components/lists/StaffsList.vue";

export default {
    data: () => ({
        value: 1234,
        valid: true,
        body: {},
        loading: false,
        ...validations,
        operationTypeItem: [
            {
                text: "تنظيف",
                value: "1"
            },
            {
                text: "صيــانة",
                value: "2"
            }
        ],
        listRooms: [],
        loadingRooms: false
    }),
    components: {
        ReservationsList,
        StaffsList
    },
    computed: {
        ...mapState([
            "bodyDetailsOpertionRoom",
            "operationRoomIdFromHome",
            "operationTypeFromHome"
        ])
    },

    created() {
        this.body = {};
        this.getRooms();
        this.body.roomId = this.operationRoomIdFromHome;
        this.body.operationType = this.operationTypeFromHome;
    },

    mounted() {
        this.$eventBus.$on("fill-fields", () => {
            // this.body = this.bodyDetails;
            this.$store.state.reservationId = this.$store.state.bodyDetails.reservationId;
            this.$store.state.staffId = this.$store.state.bodyDetails.staffId;
            this.body.staffId = this.bodyDetails.staffId;
            this.body.roomId = this.bodyDetails.roomId;
            this.body.reservationId = this.bodyDetails.reservationId;
            this.body.notes = this.bodyDetails.notes;
            this.body.id = this.bodyDetails.id;
            if (this.bodyDetails.operationType == 1)
                this.body.operationType = "1";
            else this.body.operationType = "2";
        });
    },

    methods: {
        submit() {
            if (this.bodyDetailsOpertionRoom.dialogType == "add") {
                this.addItem();
            } else {
                this.editItem();
            }
        },

        addItem() {
            this.loading = true;
            this.body.reservationId = this.$store.state.reservationId;
            this.body.staffId = this.$store.state.staffId;
            // this.body.confirmStaffId = "884a7d31-24d8-417f-bcff-6454e1fdb32f";
            this.$GenericService
                .create("operatonsRoom", this.body)
                .then(res => {
                    this.close();
                    this.$store.dispatch("setSnackbar", {
                        text: `تم الإضافة بنجاح`
                    });
                    this.$eventBus.$emit(this.$route.path);
                })
                .catch(err => {
                    this.$store.dispatch("setSnackbar", {
                        text: ` ${err.response.data.message}`,
                        color: "error"
                    });
                })
                .finally(() => (this.loading = false));
        },

        editItem() {
            this.loading = true;
            this.$GenericService
                .update(`operatonsRoom/edit/${this.body.id}`, this.body)
                .then(res => {
                    this.close();
                    this.$store.dispatch("setSnackbar", {
                        text: `تم التعديل بنجاح`
                    });
                    this.$eventBus.$emit(this.$route.path);
                })
                .catch(err => {
                    this.$store.dispatch("setSnackbar", {
                        text: ` ${err.response.data.message}`,
                        color: "error"
                    });
                })
                .finally(() => (this.loading = false));
        },

        getRooms() {
            this.loadingRooms = true;
            let isReservation = "";
            this.$GenericService
                .getAll("room/listRooms")
                .then(res => {
                    this.listRooms = res.data.data;
                })
                .catch(err => {})
                .finally(() => (this.loadingRooms = false));
        },

        close() {
            this.$store.dispatch("setDialogOpertionRoom");
            this.body = {};
            this.$store.state.reservationId = null;
            this.$store.state.staffId = null;
            this.resetValidation();
            this.$store.dispatch("setBodyDetailsOpertionRoom", {});
            this.$eventBus.$emit("RefreshHomePage");
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        }
    }
};
</script>
