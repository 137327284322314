<template>
    <div>
        <v-row no-gutters>
            <!-- User Manual  -->
            <v-col cols="12" md="1">
                <v-card flat class="py-5 mb-3">
                    <v-img
                        src="../assets/img/logo2.png"
                        class="ma-auto"
                        width="80"
                    />
                    <h6 class="pa-4 text-center blue--text font-weight-bold">
                        مركز الكفيل
                        <br />
                        <small></small>
                        لتقنية المعلومات
                    </h6>
                </v-card>

                <v-alert
                    style="font-size: 13px"
                    class="text-center alert-shadow"
                >
                    <span class="text-center">دليل المستخدم</span>
                    <div class="grey mb-6 linder-bold mt-2"></div>

                    <div class="mb-3">
                        <v-avatar tile size="20" color="blue"></v-avatar>
                        <div>
                            <small> الغرفة متوفرة </small>
                        </div>
                    </div>

                    <div class="mb-3">
                        <v-avatar tile size="20" color="grey"></v-avatar>
                        <div>
                            <small> الغرفة مشغولة </small>
                        </div>
                    </div>

                    <div class="mb-3">
                        <v-icon small color="#FA8072">fas fa-bed</v-icon>
                        <div>
                            <small>عدد اسرة الغرفة</small>
                        </div>
                    </div>

                    <div class="mb-3">
                        <v-icon>mdi mdi-broom</v-icon>
                        <div>
                            <small>الفرفة نظيفة</small>
                        </div>
                    </div>

                    <div class="mb-3">
                        <v-icon color="green">mdi mdi-broom</v-icon>
                        <div>
                            <small>جاري التنظيف</small>
                        </div>
                    </div>

                    <div class="mb-3">
                        <v-icon color="error">mdi mdi-broom</v-icon>
                        <div>
                            <small>الغرفة بحاجة الى تنظيف </small>
                        </div>
                    </div>

                    <div class="mb-3">
                        <v-icon color="grey">mdi mdi-wrench</v-icon>
                        <div>
                            <small>لا تحتاح صيانة</small>
                        </div>
                    </div>

                    <div class="mb-3">
                        <v-icon color="primary">mdi mdi-wrench</v-icon>
                        <div>
                            <small>جاري صيانة</small>
                        </div>
                    </div>
                </v-alert>
            </v-col>
            <!-- ./ User Manual -->

            <v-col cols="12" md="11">
                <!-- box filtering -->
                <v-card flat class="mr-2">
                    <v-card-actions class="py-1" @click="show = !show">
                        <v-btn color="blue" text> فلاتر العرض</v-btn>
                        <v-spacer></v-spacer>

                        <v-btn icon color="blue">
                            <v-icon>{{
                                show ? "mdi-chevron-up" : "mdi-chevron-down"
                            }}</v-icon>
                        </v-btn>
                    </v-card-actions>

                    <v-expand-transition>
                        <div v-show="show">
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row no-gutters class="my-n1">
                                    <v-col cols="12" md="3">
                                        <v-autocomplete
                                            v-model="floorId"
                                            v-debounce:500ms="getFloors"
                                            :items="floorsList"
                                            item-text="floorName"
                                            item-value="id"
                                            :loading="loading"
                                            placeholder="كل الطوابق "
                                            dense
                                            filled
                                            rounded
                                            chips
                                            small-chips
                                            @change="getItems()"
                                            no-data-text="لا توجد بيانات"
                                            hide-details="auto"
                                        />
                                    </v-col>

                                    <v-col cols="12" md="3" class="pr-1">
                                        <v-autocomplete
                                            v-model="roomTypeId"
                                            v-debounce:500ms="getRoomType"
                                            :items="roomTypes"
                                            item-text="roomTypeName"
                                            item-value="id"
                                            :loading="loading"
                                            placeholder="جميع انواع الغرف"
                                            dense
                                            filled
                                            rounded
                                            chips
                                            small-chips
                                            @change="getItems()"
                                            no-data-text="لا توجد بيانات"
                                            hide-details="auto"
                                        />
                                    </v-col>

                                    <v-col cols="12" md="3" class="pr-1">
                                        <v-select
                                            v-model="bedCount"
                                            :items="countBed"
                                            @change="getItems()"
                                            item-text="text"
                                            item-value="value"
                                            placeholder="عرض حسب سعة الغرف"
                                            hide-details="auto"
                                            dense
                                            filled
                                            rounded
                                            persistent-hint
                                            small-chips
                                        >
                                        </v-select>
                                    </v-col>

                                    <v-col cols="12" md="12" class="mr-n2 mt-1">
                                        <v-chip-group color="blue">
                                            <v-chip
                                                filter
                                                v-for="(item,
                                                index) in optionsRoom"
                                                :key="index"
                                                @click="
                                                    filterStatusRoom(item.value)
                                                "
                                            >
                                                {{ item.text }}
                                            </v-chip>
                                        </v-chip-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </div>
                    </v-expand-transition>
                </v-card>
                <!-- ./ box filtering -->

                <div class="message-no-data" v-if="floors == ''">
                    <h5>لا توجد بيانات</h5>
                    <div class="ma-12 pa-12">
                        <v-progress-linear
                            class="mx-12"
                            :active="loadingFloors"
                            :indeterminate="loadingFloors"
                            color="deep-purple accent-4"
                        >
                        </v-progress-linear>
                    </div>
                </div>
                <v-card
                    flat
                    v-for="(floor, index) in floors"
                    :key="index"
                    color="rgba(0, 0, 0, 0)"
                >
                    <v-toolbar flat color="rgba(0, 0, 0, 0)">
                        <span class="blue--text">
                            {{ floor.floorName }}
                        </span>
                        <v-spacer />
                        <v-chip
                            small
                            color="blue--text"
                            class="font-weight-bold"
                        >
                            <small>
                                عدد غرف الطابق
                                <span class="mr-1">{{ floor.roomsCount }}</span>
                            </small>
                        </v-chip>
                        <v-chip
                            small
                            color="blue--text"
                            class="mr-1 font-weight-bold"
                        >
                            <small>
                                المشغولة
                                <span class="mr-1"> 0 </span>
                            </small>
                        </v-chip>
                        <v-chip
                            small
                            color="blue--text"
                            class="mr-1 font-weight-bold"
                        >
                            <small>
                                المحجوزة
                                <span class="mr-1">{{
                                    floor.roomsReservationCount
                                }}</span>
                            </small>
                        </v-chip>
                        <v-chip
                            small
                            color="blue--text"
                            class="mr-1 font-weight-bold"
                        >
                            <small>
                                الغارغة
                                <span class="mr-1">{{
                                    floor.roomsNotReservationCount
                                }}</span>
                            </small>
                        </v-chip>
                    </v-toolbar>

                    <v-container fluid>
                        <v-row no-gutters>
                            <!-- message not data -->
                            <div
                                class="text-center my-6 mx-auto"
                                v-if="floor.rooms == ''"
                            >
                                لا توجد غرف
                            </div>

                            <!-- box room -->
                            <v-col
                                cols="12"
                                md="2"
                                class="pl-2"
                                v-for="(room, index) in floor.rooms"
                                :key="index"
                            >
                                <v-card
                                    flat
                                    tile
                                    class="mb-3 box-shadow"
                                    :dark="!room.isReservation"
                                    :class="
                                        room.isReservation
                                            ? 'grey lighten-2'
                                            : 'blue darken-2'
                                    "
                                >
                                    <div class="pa-2">
                                        <div
                                            class="d-flex justify-space-between"
                                        >
                                            <small
                                                :class="
                                                    $vuetify.theme.dark &&
                                                    room.isReservation
                                                        ? ' black--text'
                                                        : ''
                                                "
                                            >
                                                {{
                                                    room.isReservation
                                                        ? "مشغولة"
                                                        : "متوفرة"
                                                }}
                                                <v-icon
                                                    class="mr-1"
                                                    x-small
                                                    v-if="!room.isReservation"
                                                    >mdi-check-circle</v-icon
                                                >
                                                <v-icon
                                                    class="mr-1"
                                                    x-small
                                                    color="#FA8072"
                                                    v-if="room.isReservation"
                                                    >mdi-account-supervisor-circle</v-icon
                                                >
                                            </small>
                                            <small
                                                :class="
                                                    $vuetify.theme.dark &&
                                                    room.isReservation
                                                        ? ' black--text'
                                                        : ''
                                                "
                                            >
                                                {{ room.roomTypeName }} /
                                                <small>
                                                    {{ room.totalPrice }} د.ع
                                                </small>
                                            </small>
                                        </div>

                                        <h1
                                            :class="
                                                $vuetify.theme.dark &&
                                                room.isReservation
                                                    ? ' black--text'
                                                    : ''
                                            "
                                            class="display-1 text-center font-weight-bold mt-4"
                                        >
                                            {{ room.roomNumber }}
                                        </h1>

                                        <v-icon
                                            v-for="(bed, i) in room.bedCount"
                                            :key="i"
                                            class="ml-1"
                                            small
                                            :color="
                                                room.isReservation
                                                    ? '#FA8072'
                                                    : '#ffffff'
                                            "
                                        >
                                            fas fa-bed
                                        </v-icon>
                                    </div>

                                    <v-card
                                        light
                                        tile
                                        flat
                                        class="pa-1 d-flex justify-start"
                                        style="font-size: 12px"
                                    >
                                        <v-row no-gutters>
                                            <v-col cols="5">
                                                <span
                                                    v-if="room.isMaintenance"
                                                    class="mt-3"
                                                >
                                                    <v-icon
                                                        small
                                                        color="primary"
                                                        >mdi mdi-wrench</v-icon
                                                    >
                                                    <small class="ma-1">
                                                        جاري صيانة
                                                    </small>
                                                </span>

                                                <v-tooltip
                                                    bottom
                                                    v-if="!room.isMaintenance"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on
                                                        }"
                                                    >
                                                        <v-btn
                                                            @click="
                                                                openDirectOperatons(
                                                                    room.id,
                                                                    '2'
                                                                )
                                                            "
                                                            v-on="on"
                                                            text
                                                            x-small
                                                        >
                                                            <v-icon
                                                                :disabled="
                                                                    !room.isMaintenance
                                                                "
                                                                small
                                                                color="primary"
                                                                >mdi
                                                                mdi-wrench</v-icon
                                                            >
                                                            <small class="mr-1">
                                                                لا تحتاج صيانة
                                                            </small>
                                                        </v-btn>
                                                    </template>
                                                    <span>صيانة الغرفة</span>
                                                </v-tooltip>
                                            </v-col>
                                            <v-col cols="5">
                                                <span
                                                    class="mt-3"
                                                    v-if="
                                                        room.isCleaning == 0 ||
                                                            room.isCleaning == 2
                                                    "
                                                >
                                                    <v-icon
                                                        :disabled="
                                                            !room.isCleaning !=
                                                                0
                                                        "
                                                        small
                                                        :color="
                                                            room.isCleaning == 2
                                                                ? 'green'
                                                                : 'error'
                                                        "
                                                        >mdi-broom</v-icon
                                                    >
                                                    <small
                                                        class="ma-1"
                                                        v-if="
                                                            room.isCleaning == 0
                                                        "
                                                    >
                                                        نظيفة
                                                    </small>
                                                    <small
                                                        class="ma-1"
                                                        v-if="
                                                            room.isCleaning == 2
                                                        "
                                                        >جاري التنظيف</small
                                                    >
                                                </span>

                                                <v-tooltip
                                                    bottom
                                                    v-if="room.isCleaning == 1">
                                                    <template
                                                        v-slot:activator="{on}"
                                                    >
                                                        <v-btn
                                                            @click="
                                                                openDirectOperatons(
                                                                    room.id,
                                                                    '1'
                                                                )
                                                            "
                                                            v-on="on"
                                                            text
                                                            x-small
                                                            class="px-0"
                                                        >
                                                            <v-icon
                                                                :disabled="
                                                                    !room.isCleaning !=
                                                                        0
                                                                "
                                                                small
                                                                :color="
                                                                    room.isCleaning ==
                                                                    2
                                                                        ? 'green'
                                                                        : 'error'
                                                                "
                                                                >mdi-broom</v-icon
                                                            >
                                                            <small
                                                                class="mr-1"
                                                                v-if="
                                                                    room.isCleaning ==
                                                                        1
                                                                "
                                                                >بحاجة الى
                                                                تنظيف</small
                                                            >
                                                        </v-btn>
                                                    </template>
                                                    <span>تنظيف الغرفة</span>
                                                </v-tooltip>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on
                                                        }"
                                                    >
                                                        <v-btn
                                                            v-on="on"
                                                            text
                                                            class="primary--text font-weight-bold"
                                                            x-small
                                                            @click="openReservations(room)">
                                                            حجز
                                                        </v-btn>
                                                    </template>
                                                    <span>حجز الغرفة</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <!-- ./ list card orgs -->

        <Dialog ref="dialog" />
        <DialogAdd ref="dialogAdd" />
    </div>
</template>
<script>
import Dialog from "./Reservation/Dialog.vue";
import DialogAdd from "./OperatonsRoom/DialogAdd.vue";

export default {
    components: {
        Dialog,
        DialogAdd
    },

    data() {
        return {
            floorId: "",
            roomTypeId: "",
            floors: [],
            floorsList: [],
            roomTypes: [],
            loading: false,
            countBed: [
                {
                    text: "كل الغرف",
                    value: ""
                },
                {
                    text: "الغرف المفردة",
                    value: 1
                },
                {
                    text: "الغرف 2 سرير",
                    value: 2
                },
                {
                    text: "الغرف 3 سرير",
                    value: 3
                },
                {
                    text: "الغرف 4 سرير",
                    value: 4
                },
                {
                    text: "الغرف 5 سرير",
                    value: 5
                },
                {
                    text: "الغرف 6 سرير",
                    value: 6
                }
            ],
            optionsRoom: [
                {
                    text: "الكــل",
                    value: 0
                },
                {
                    text: "الفرف المشغولة",
                    value: 1
                },
                {
                    text: "الفرف الفارغة",
                    value: 2
                },
                {
                    text: "غرف بحاجة الى تنظيف ",
                    value: 3
                },
                {
                    text: "غرف بحاجة الى صيانة",
                    value: 4
                }
            ],
            bedCount: "",
            isCleaning: "",
            isReservation: "",
            isMaintenance: "",
            constfloors: [],
            show: false,
            loadingFloors: false
        };
    },

    created() {
        this.$eventBus.$on("RefreshHomePage", () => {
            this.getItems();
        });
        this.getItems();
        this.getFloors();
        this.getRoomType();
    },

    methods: {
        getItems() {
            this.loadingFloors = true;
            this.$GenericService
                .getAll(
                    `floor/getFloorWithAllRooms?floorId=${this.floorId}&roomTypeId=${this.roomTypeId}&bedCount=${this.bedCount}&isCleaning=${this.isCleaning}&isReservation=${this.isReservation}&isMaintenance=${this.isMaintenance}`
                )
                .then(res => {
                    this.floors = res.data.data;
                    this.loadingFloors = false;
                })
                .catch(err => {});
        },

        filterStatusRoom(i) {
            switch (i) {
                case 1:
                    this.isReservation = true;
                    break;

                case 2:
                    this.isReservation = false;
                    break;

                case 3:
                    this.isCleaning = 0;
                    break;

                case 4:
                    this.isMaintenance = true;
                    break;

                default:
                    break;
            }

            this.getItems();
            this.isCleaning = "";
            this.isReservation = "";
            this.isMaintenance = "";
        },

        getFloors() {
            this.loading = true;
            this.$GenericService
                .getAll("room/getFloors")
                .then(res => {
                    this.floorsList = res.data.data;
                    this.floorsList.unshift({
                        id: "",
                        floorName: "كل الطوابق"
                    });
                })
                .catch(err => {
                    // console.log(err);
                })
                .finally(() => (this.loading = false));
        },

        getRoomType() {
            this.loading = true;
            this.$GenericService
                .getAll("room/getRoomTypes")
                .then(res => {
                    this.roomTypes = res.data.data;
                    this.roomTypes.unshift({
                        id: "",
                        roomTypeName: "جميع انواع الغرف"
                    });
                })
                .catch(err => {
                    // console.log(err);
                })
                .finally(() => (this.loading = false));
        },

        openReservations(room) {
            var item = {
                dialogType: "add"
            };
            this.$store.dispatch("setBodyDetails", item);
            this.$store.dispatch("setDialog");
            this.$eventBus.$emit("refresh-rooms");
            this.$store.dispatch("setReservedRooms", room);
        },

        openDirectOperatons(roomId, operationType) {
            // this.$router.push("/operatons");
            var item = {
                dialogType: "add"
            };
            this.$store.dispatch("setBodyDetailsOpertionRoom", item);
            this.$store.dispatch("setDialogOpertionRoom");
            this.$store.dispatch("setOperationRoomId", roomId);
            this.$store.dispatch("setOperationType", operationType);
        }
    }
};
</script>

<style>
.icon {
    width: 25px;
    display: inline-block;
    margin-bottom: -3px;
}
.box {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-bottom: -10px;
}
.linder-bold {
    height: 3px;
    border-radius: 3px;
    opacity: 0.5;
}
.border-button {
    border-bottom: dotted 1px grey;
    padding-bottom: 5px;
    margin: 2px 0;
}
.alert-shadow {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 12px !important;
}

.box-shadow {
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}
.card-Hover:hover {
    transition: all 0.5s ease-in-out;
    transform: scale(1.4);
    z-index: 100;
    cursor: pointer;
}

.message-no-data {
    text-align: center;
    margin-top: 100px;
}

.mt--3 {
    margin-top: 3px;
}
</style>
